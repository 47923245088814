<template>
  <div style="height: inherit">

    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />

    <div class="email-app-list">
      <form-geral
        ref="formGeral"
        @getInfoVies="getInfoVies"
      />
    </div>

    <portal
      to="content-renderer-sidebar-left"
    >
      <form-left-sidebar
        ref="leftSidebar"
        :class="{'show': mqShallShowLeftSidebar}"
        :save-form="saveForm"
        :cancel-form="cancelForm"
        :clear-form="clearForm"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>

  </div>

</template>

<script>
import store from '@/store'
import '@/libs/sweet-alerts'
import crmClientsFormModule from '@store-modules/crm/clients/form'
import { onUnmounted } from '@vue/composition-api'
import { useRouter, showModalMessage } from '@core/utils/utils'
// import { mapGetters } from 'vuex'
import { VeeValidateLoadLocale } from '@core-custom/mixins/geral'
import FormLeftSidebar from './leftSidebar.vue'
import FormGeral from './geral.vue'

export default {
  components: {
    FormLeftSidebar,
    FormGeral,
  },
  mixins: [VeeValidateLoadLocale],
  computed: {
    mqShallShowLeftSidebar: {
      get() {
        return this.$store.getters['crm_clients_form/mqShallShowLeftSidebar']
      },
      set(newValue) {
        this.$store.commit('crm_clients_form/setMqShallShowLeftSidebar', newValue)
      },
    },
  },
  async created() {
    await this.renderCreated()
  },
  methods: {
    async saveForm() {
      const self = this
      if ((typeof self.clicked === 'undefined') || (self.clicked === false)) {
        self.clicked = true

        await this.$store.dispatch('crm_clients_form/setIsSavingData', true)
        await self.$refs.formGeral.$refs.formSaveClientValidator.validateWithInfo()
          .then(async ({ isValid, errors }) => {
            if (isValid) {
              this.$root.$emit('app::loading', {
                status: true,
                zIndex: 5,
                txt: this.$t('Aguarde, a processar...'),
              })

              await this.$store.dispatch('crm_clients_form/save').then(async resSave => {
                if (resSave.save === true) {
                  this.$root.$emit('app::loading', false)

                  const txtMsgSuccess = resSave.msgSuccessSave || this.$t('Cliente guardado com sucesso')

                  await this.showMsgSuccessRequest({ message: txtMsgSuccess }).then(() => {
                    if (this.messageInfo !== '') {
                      this.showMsgSuccessRequest({ message: this.messageInfo }).then(() => {
                        this.redirectPageBack()
                      })
                    } else {
                      this.redirectPageBack()
                    }
                  })
                }
              }).catch(error => {
                throw error
              })
            } else {
              throw errors
            }
          })
          .catch(async error => {
            const oError = error

            this.$root.$emit('app::loading', false)
            this.showMsgErrorRequestForm(oError, false)
            await this.$store.dispatch('crm_clients_form/setIsSavingData', false)
          })

        setTimeout(() => {
          self.clicked = false
        }, 1000)
      }
    },

    async cancelForm() {
      const self = this

      const actionModal = new Promise(resolve => {
        showModalMessage(resolve)
      })

      await actionModal.then(result => {
        if (result === 'confirm') {
          self.redirectPageBack()
        }
      })
    },

    clearForm() {
      this.renderCreated()
    },

    async redirectPageBack() {
      this.$router.push({ name: 'crm-clients-list' }).then(() => {
        // this.$root.$emit('app::loading', false)
      })
    },

    async showMsgErrorRequestForm(error, errorFielsRequired) {
      let messageAlertError = ''

      if (error instanceof Error) {
        if (error.message !== '') {
          if (errorFielsRequired === true) {
            messageAlertError += `<h3 class="text-center text-danger mb-2">${this.$tc('Preencha o campo obrigatório', Object.keys(error).length)}</h3>`
          } else {
            messageAlertError += `<h3 class="text-center text-danger mb-2">${this.$t('Alerta')}</h3>`
          }

          messageAlertError += `<p class="mb-1">${error.message}</p>`
        }
      } else if (Object.keys(error).length > 0) {
        if ((Object.keys(error).length === 1) && ('message' in error) && (error.message === '')) {
          messageAlertError = ''
        } else {
          messageAlertError += `<h3 class="text-center text-danger mb-2">${this.$tc('Preencha o campo obrigatório', Object.keys(error).length)}</h3>`
          Object.keys(error).forEach(key => {
            if (error[key].length > 0) {
              error[key].forEach(txt => {
                messageAlertError += `<p class="mb-1">${txt}</p>`
              })
            }
          })
        }
      }

      if (messageAlertError !== '') {
        this.$swal({
          title: '',
          html: messageAlertError,
          icon: '',
          width: 600,
          confirmButtonText: this.$t('Fechar'),
          customClass: {
            confirmButton: 'btn btn-outline-primary btn-xs-block',
            htmlContainer: 'text-left',
            content: 'pl-0 pr-0 pl-sm-2 pr-sm-2',
          },
          buttonsStyling: false,
        })
      }
    },

    async showMsgSuccessRequest(info) {
      if (info.message) {
        await this.$swal({
          title: '',
          html: info.message,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-outline-primary btn-xs-block',
          },
          buttonsStyling: false,
        })
      }
    },

    async renderCreated() {
      await this.$store.commit('crm_clients_form/resetState')
    },

    async getInfoVies(value) {
      this.$root.$emit('app::loading', true)

      await this.$store.dispatch('crm_clients_form/getInfoVies', { number: value || '' }).then(async data => {
        if (data?.name !== undefined) {
          await this.$store.dispatch('crm_clients_form/updateFieldValue', { field: 'nome', value: data.name })
        }

        if (data?.address !== undefined) {
          await this.$store.dispatch('crm_clients_form/updateFieldValue', { field: 'morada', value: data.address })
        }

        if (data?.code_postal !== undefined) {
          await this.$store.dispatch('crm_clients_form/updateFieldValue', { field: 'codpost', value: data.code_postal })
        }

        if (data?.city !== undefined) {
          await this.$store.dispatch('crm_clients_form/updateFieldValue', { field: 'local', value: data.city })
        }

        if (data?.county !== undefined) {
          await this.$store.dispatch('crm_clients_form/updateFieldValue', { field: 'zona', value: data.county })
        }

        this.$root.$emit('app::loading', false)
      })
        .catch(error => {
          this.$root.$emit('app::loading', false)
          this.showMsgErrorRequestForm(error, false)
        })
    },
  },
  setup() {
    const NAMESPACE_CLIENTS_FORM = 'crm_clients_form'

    if (!store.hasModule(NAMESPACE_CLIENTS_FORM)) {
      store.registerModule(NAMESPACE_CLIENTS_FORM, crmClientsFormModule)

      onUnmounted(() => {
        if (store.hasModule(NAMESPACE_CLIENTS_FORM)) store.unregisterModule(NAMESPACE_CLIENTS_FORM)
      })
    }

    const { route } = useRouter()
    const paramsUrl = route.value.params
    store.dispatch('crm_clients_form/setParamsUrl', paramsUrl)

    return {
      NAMESPACE_CLIENTS_FORM,

      paramsUrl,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
