import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import { defaultState, defaultStateRouter } from './defaultState'

export default {
  namespaced: true,
  state: { ...defaultState(), ...defaultStateRouter() },
  getters: {
    mqShallShowLeftSidebar: state => state.mqShallShowLeftSidebar.value,
    tabContentStepShow: state => state.tabContentStepShow.value,
    form_data: state => state.form_data.value,
    vars: state => ({
      isSavingData: state.isSavingData.value,
    }),
    keyReg: state => state.keyReg.value,
    dynamicIDForm: state => state.dynamicIDForm.value,
    modeFormAction: state => state.modeFormAction.value,
    loaded: state => state.loaded.value,
    messageInfo: state => state.messageInfo,
  },
  mutations: {
    resetState(state) {
      const updateState = { ...defaultState() }
      const defaultStateRouterDefault = defaultStateRouter()

      if (Object.keys(defaultStateRouterDefault).length > 0) {
        Object.keys(defaultStateRouterDefault).forEach(index => {
          updateState[index] = state[index]
        })
      }

      Object.assign(state, updateState)
    },
    setMqShallShowLeftSidebar(state, payload) {
      state.mqShallShowLeftSidebar.value = payload
    },
    setUpdateFormData(state, payload) {
      if (payload === null || payload === '') {
        state.form_data.value = {}
      } else {
        state.form_data.value = { ...payload }
      }
    },
    setDynamicIDForm(state, payload) {
      state.dynamicIDForm.value = payload || ''
    },
    setModeViewDetail(state, payload) {
      state.modeFormAction.value = payload
    },
    setOptionsYesNo(state, payload) {
      state.optionsYesNo.value = payload
    },
    setUpdateFormDataField(state, payload) {
      try {
        if (payload.field) {
          state.form_data.value[payload.field] = payload.value || ''
        }
      } catch (err) {
        //
      }
    },
    setLoaded(state, payload) {
      state.loaded.value = payload
    },
    setParamsUrl(state, payload) {
      state.paramsUrl.value = payload
    },
    setTypeAction(state, payload) {
      state.typeAction.value = payload || ''
    },
    setIsSavingData(state, payload) {
      state.isSavingData.value = payload
    },
    setKeyReg(state, payload) {
      state.keyReg.value = payload
    },
    setMessageInfo(state, payload) {
      state.messageInfo = payload || ''
    },
  },
  actions: {

    updateFieldValue({ state, commit }, payload) {
      if (Array.isArray(payload)) {
        payload.forEach(field => {
          commit('setUpdateFormDataField', field)
        })
      } else {
        commit('setUpdateFormDataField', payload)
      }
      commit('setUpdateFormData', state.form_data.value)
    },

    setLoaded({ commit }) {
      commit('setLoaded', true)
    },

    setParamsUrl({ commit }, payload) {
      commit('setParamsUrl', payload)
    },

    setIsSavingData({ commit }, payload) {
      commit('setIsSavingData', payload)
    },

    setModeViewDetail({ commit }, payload) {
      commit('setModeViewDetail', payload)
    },

    async save({ state }) {
      const formData = new FormData()
      formData.append('ncont', state.form_data.value.ncont || '')
      formData.append('nome', state.form_data.value.nome || '')
      formData.append('nome2', state.form_data.value.nome2 || '')
      formData.append('morada', state.form_data.value.morada || '')
      formData.append('local', state.form_data.value.local || '')
      formData.append('codpost', state.form_data.value.codpost || '')
      formData.append('zona', state.form_data.value.zona || '')
      formData.append('tlmvl', state.form_data.value.tlmvl || '')
      formData.append('email', state.form_data.value.email || '')

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}index.php?dispatch=bestquimica_api.crm_create_new_client`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              if (Number(response.data.error) === 1) {
                reject(new Error(response.data.msg))
              } else {
                resolve({ save: true })
              }
            } else {
              reject(new Error(i18n.t('Problema ao guardar o cliente')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao guardar o cliente')))
            }
          })
      })
    },

    async getInfoVies({ dispatch }, payload) {
      const formData = new FormData()
      formData.append('number', payload?.number || '')

      await dispatch('request/cancelTokensPendingByUrl', 'index.php?dispatch=bestquimica_api.getInfoVatNumber', { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}index.php?dispatch=bestquimica_api.getInfoVatNumber`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              if (Number(response.data.error) === 1) {
                reject(new Error(response.data.msg))
              } else {
                resolve(response.data.info || {})
              }
            } else {
              reject(new Error(i18n.t('Não foi possível obter a informação do NIPC / NIF no VIES')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Não foi possível obter a informação do NIPC / NIF no VIES')))
            }
          })
      })
    },

  },
}
