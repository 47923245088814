import { ref } from '@vue/composition-api'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'

const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

export const defaultState = () => ({
  mqShallShowLeftSidebar,
  loaded: ref(false),
  form_data: ref({}),
  isSavingData: ref(false),
  messageInfo: '',
  keyReg: ref(''),
  dynamicIDForm: ref(''),
})

export const defaultStateRouter = () => ({
  paramsUrl: ref({}),
  typeAction: ref(''),
  modeFormAction: ref(''),
})
