<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div
          :class="{'email-app-menu': true, 'sws-not-button-create': false }"
        >
          <template>
            <div
              class="form-group-compose text-center compose-btn"
            >
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                block
                :disabled="vars.isSavingData"
                class="sw-button-action"
                @click.stop.prevent="saveForm(); $emit('close-left-sidebar');"
              >
                <span
                  class="align-middle"
                >{{ $t('Guardar') }}</span>
              </b-button>
            </div>
          </template>

          <vue-perfect-scrollbar
            class="sidebar-menu-list scroll-area"
            :settings="configSettingsScroll"
          >

            <!-- Botoes acoes secundarias -->
            <b-list-group>

              <b-list-group-item>
                <b-button
                  v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                  variant="outline-secondary"
                  block
                  :disabled="vars.isSavingData"
                  class="sw-button-action"
                  @click.stop.prevent="clearForm();"
                >
                  {{ $t('Limpar') }}
                </b-button>
              </b-list-group-item>

              <!-- Cancelar -->
              <b-list-group-item>
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  block
                  :disabled="vars.isSavingData"
                  class="sw-button-action"
                  @click.stop.prevent="cancelForm(); $emit('close-left-sidebar')"
                >
                  <span class="align-middle">{{ $t('Cancelar') }}</span>
                </b-button>
              </b-list-group-item>

            </b-list-group>

            <!-- Extra space -->
            <h6
              class="section-label mt-3 mb-1 px-2"
            >
              {{ '\xa0' }}
            </h6>

          </vue-perfect-scrollbar>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import Ripple from 'vue-ripple-directive'
import {
  BButton, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { mapGetters } from 'vuex'
import { configSettingsScroll } from '@core-custom/utils/ui'

export default {
  directives: {
    Ripple,
  },
  components: {
    VuePerfectScrollbar,
    BButton,
    BListGroup,
    BListGroupItem,
  },
  mixins: [sizeIconsAction],
  props: {
    saveForm: {
      type: Function,
      required: true,
    },
    cancelForm: {
      type: Function,
      required: true,
    },
    clearForm: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapGetters('crm_clients_form', ['vars']),
  },
  setup() {
    return {
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss" scoped>
.email-application .content-area-wrapper .sidebar .email-app-sidebar .email-app-menu.sws-not-button-create .sidebar-menu-list {
  height: calc(100%);
}
</style>
