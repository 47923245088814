var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form',{ref:"formSaveLead",on:{"submit":function($event){$event.preventDefault();}}},[_c('validation-observer',{ref:"formSaveClientValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('div',{staticClass:"email-app-list position-relative height-100-panel"},[_c('div',{staticClass:"app-fixed-search d-flex align-items-center d-block d-lg-none"},[_c('div',{staticClass:"d-flex align-items-center flex-grow-1 w-50"},[_c('div',{staticClass:"sidebar-toggle ml-1"},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"MenuIcon","size":_vm.sizeIconsAction},on:{"click":function($event){_vm.mqShallShowLeftSidebar = true}}})],1)])]),_c('vue-perfect-scrollbar',{staticClass:"scroll-content",attrs:{"settings":_vm.configSettingsScroll}},[_c('div',{staticClass:"app-fixed-search d-flex align-items-center",class:{'border-top-theme' : false }},[_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('div',{staticClass:"d-flex align-items-center justify-content-start w-100"},[_c('h4',{staticClass:"p-1 m-0"},[_vm._v(" "+_vm._s(_vm.$t('Geral'))+" ")])])])]),_c('b-container',{staticClass:"p-2",attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('NIPC / NIF')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('NIPC / NIF')}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.tokenMaskNIF),expression:"tokenMaskNIF"}],class:errors.length > 0 ? 'is-invalid':null,attrs:{"placeholder":"","autocomplete":"off","maxlength":"20"},on:{"blur":function($event){return _vm.$emit('getInfoVies', _vm.form_data.ncont)}},model:{value:(_vm.form_data.ncont),callback:function ($$v) {_vm.$set(_vm.form_data, "ncont", $$v)},expression:"form_data.ncont"}})],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('empresa / nome')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Empresa / Nome')}},[_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"placeholder":"","autocomplete":"off","maxlength":"55"},model:{value:(_vm.form_data.nome),callback:function ($$v) {_vm.$set(_vm.form_data, "nome", $$v)},expression:"form_data.nome"}})],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('abreviatura')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Abreviatura')}},[_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"placeholder":"","autocomplete":"off","maxlength":"55"},model:{value:(_vm.form_data.nome2),callback:function ($$v) {_vm.$set(_vm.form_data, "nome2", $$v)},expression:"form_data.nome2"}})],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('morada')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Morada')}},[_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"placeholder":"","autocomplete":"off","maxlength":"55"},model:{value:(_vm.form_data.morada),callback:function ($$v) {_vm.$set(_vm.form_data, "morada", $$v)},expression:"form_data.morada"}})],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('código postal')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Código postal')}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.tokenMaskCodigoPostal),expression:"tokenMaskCodigoPostal"}],class:errors.length > 0 ? 'is-invalid':null,attrs:{"placeholder":"","autocomplete":"off","maxlength":"35"},model:{value:(_vm.form_data.codpost),callback:function ($$v) {_vm.$set(_vm.form_data, "codpost", $$v)},expression:"form_data.codpost"}})],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('localidade / cidade')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Localidade / Cidade')}},[_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"placeholder":"","autocomplete":"off","maxlength":"43"},model:{value:(_vm.form_data.local),callback:function ($$v) {_vm.$set(_vm.form_data, "local", $$v)},expression:"form_data.local"}})],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('concelho')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Concelho')}},[_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"placeholder":"","autocomplete":"off","maxlength":"20"},model:{value:(_vm.form_data.zona),callback:function ($$v) {_vm.$set(_vm.form_data, "zona", $$v)},expression:"form_data.zona"}})],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"r":"","name":_vm.$t('contacto')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Contacto')}},[_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"placeholder":"","autocomplete":"off","maxlength":"45"},model:{value:(_vm.form_data.tlmvl),callback:function ($$v) {_vm.$set(_vm.form_data, "tlmvl", $$v)},expression:"form_data.tlmvl"}})],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"rules":"email","name":_vm.$t('email')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Email')}},[_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"placeholder":"","autocomplete":"off","maxlength":"100"},model:{value:(_vm.form_data.email),callback:function ($$v) {_vm.$set(_vm.form_data, "email", $$v)},expression:"form_data.email"}})],1)]}}],null,true)})],1)],1)],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }