<template>
  <div>
    <b-form
      ref="formSaveLead"
      @submit.prevent
    >
      <validation-observer
        ref="formSaveClientValidator"
        #default="{}"
      >

        <div
          class="email-app-list position-relative height-100-panel"
        >
          <div
            class="app-fixed-search d-flex align-items-center d-block d-lg-none"
          >
            <div class="d-flex align-items-center flex-grow-1 w-50">

              <div class="sidebar-toggle ml-1">
                <feather-icon
                  icon="MenuIcon"
                  :size="sizeIconsAction"
                  class="cursor-pointer"
                  @click="mqShallShowLeftSidebar = true"
                />
              </div>

            </div>
          </div>

          <vue-perfect-scrollbar
            class="scroll-content"
            :settings="configSettingsScroll"
          >
            <div
              class="app-fixed-search d-flex align-items-center"
              :class="{'border-top-theme' : false }"
            >
              <div class="d-flex flex-column align-items-center">
                <div class="d-flex align-items-center justify-content-start w-100">
                  <h4 class="p-1 m-0">
                    {{ $t('Geral') }}
                  </h4>
                </div>
              </div>
            </div>

            <b-container
              class="p-2"
              fluid
            >
              <b-row>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="$t('NIPC / NIF')"
                  >
                    <b-form-group
                      :label="$t('NIPC / NIF')"
                    >
                      <b-form-input
                        v-model="form_data.ncont"
                        v-mask="tokenMaskNIF"
                        placeholder=""
                        autocomplete="off"
                        maxlength="20"
                        :class="errors.length > 0 ? 'is-invalid':null"
                        @blur="$emit('getInfoVies', form_data.ncont)"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="$t('empresa / nome')"
                  >
                    <b-form-group
                      :label="$t('Empresa / Nome')"
                    >
                      <b-form-input
                        v-model="form_data.nome"
                        placeholder=""
                        autocomplete="off"
                        maxlength="55"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('abreviatura')"
                  >
                    <b-form-group
                      :label="$t('Abreviatura')"
                    >
                      <b-form-input
                        v-model="form_data.nome2"
                        placeholder=""
                        autocomplete="off"
                        maxlength="55"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="$t('morada')"
                  >
                    <b-form-group
                      :label="$t('Morada')"
                    >
                      <b-form-input
                        v-model="form_data.morada"
                        placeholder=""
                        autocomplete="off"
                        maxlength="55"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="$t('código postal')"
                  >
                    <b-form-group
                      :label="$t('Código postal')"
                    >
                      <b-form-input
                        v-model="form_data.codpost"
                        v-mask="tokenMaskCodigoPostal"
                        placeholder=""
                        autocomplete="off"
                        maxlength="35"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="$t('localidade / cidade')"
                  >
                    <b-form-group
                      :label="$t('Localidade / Cidade')"
                    >
                      <b-form-input
                        v-model="form_data.local"
                        placeholder=""
                        autocomplete="off"
                        maxlength="43"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="$t('concelho')"
                  >
                    <b-form-group
                      :label="$t('Concelho')"
                    >
                      <b-form-input
                        v-model="form_data.zona"
                        placeholder=""
                        autocomplete="off"
                        maxlength="20"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    r
                    :name="$t('contacto')"
                  >
                    <b-form-group
                      :label="$t('Contacto')"
                    >
                      <b-form-input
                        v-model="form_data.tlmvl"
                        placeholder=""
                        autocomplete="off"
                        maxlength="45"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    rules="email"
                    :name="$t('email')"
                  >
                    <b-form-group
                      :label="$t('Email')"
                    >
                      <b-form-input
                        v-model="form_data.email"
                        placeholder=""
                        autocomplete="off"
                        maxlength="100"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

            </b-container>
          </vue-perfect-scrollbar>

        </div>
      </validation-observer>
    </b-form>

  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BContainer,
  BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mask } from 'vue-the-mask'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BContainer,
    BForm,
    VuePerfectScrollbar,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    mask,
  },
  mixins: [sizeIconsAction],
  data() {
    return {
      showModalViewRequestRGPD: false,
      dataModalViewRequestRGPD: [],
      tokenMaskCodigoPostal: {
        mask: 'XXXX-XXX',
        tokens: {
          X: {
            pattern: /[0-9]/,
            transform(v) {
              return v.toLocaleUpperCase()
            },
          },
        },
      },
      tokenMaskNIF: {
        mask: '#########',
        tokens: {
          '#': {
            pattern: /\d/,
          },
        },
      },
    }
  },
  computed: {
    ...mapGetters('crm_clients_form', ['form_data']),
    mqShallShowLeftSidebar: {
      get() {
        return this.$store.getters['crm_clients_form/mqShallShowLeftSidebar']
      },
      set(newValue) {
        this.$store.commit('crm_clients_form/setMqShallShowLeftSidebar', newValue)
      },
    },
  },
  setup() {
    const { t } = useI18nUtils()

    return {
      t,

      configSettingsScroll,
    }
  },
}
</script>
